<template>
  <shipblu-card :cardLoading="priceCardLoading" class="p-5">
    <p class="header">{{ $t('Price') }}</p>
    <div v-if="orderData.is_exchange && $route.params.type === 'returns'">
      <p class="text-darkgray font-medium text-sm mt-1 leading-tight">{{ $t('This Order is part of an exchange order . Please, Check the price on the exchange order.') }}</p>
    </div>
    <div v-if="orderData.is_exchange">
      <p class="header text-base mt-4">{{ $route.params.type === 'delivery-orders' ? $t('Linked Return Order') : $t('Linked Delivery Order') }}</p>
      <p class="text-darkgray font-medium mt-1 leading-tight">
        {{ $t('Tracking Number') }}:
        <router-link :to="{ name: `${$store.state.AppActiveUser.userRole}-${routerTypesDic[$route.params.type]}`, params: { type: routerTypesDic[$route.params.type], orderID: $route.params.type === 'delivery-orders' ? orderData.linked_exchange_order.return_order.id : orderData.linked_exchange_order.delivery_order.id }}">
          <span class="text-primary underline font-semibold cursor-pointer">{{ $route.params.type === 'delivery-orders' ? orderData.linked_exchange_order.return_order.tracking_number : orderData.linked_exchange_order.delivery_order.tracking_number }}</span>
        </router-link>
      </p>
    </div>
    <div class="mt-4" v-if="(orderData.is_exchange && $route.params.type !== 'returns') || !orderData.is_exchange">
      <div v-if="orderData.tracking_number && orderData.tracking_number[0] !== '4'">
        <div class="flex items-center justify-between gap-2 mt-3" v-for="price in orderData.order_price.price_breakdown_item" :key="price.index">
          <p class="text-darkgray text-sm font-medium">{{ price ? price.description : '' }}</p>
          <p class="font-semibold text-black">{{ price ? Number(price.value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '' }}</p>
        </div>
        <div class="flex items-center justify-between gap-2 mt-3" v-for="discount in orderData.order_price.discount_breakdown_item" :key="discount.index">
          <p class="text-darkgray text-sm font-medium">{{ discount ? discount.description : '' }}</p>
          <p class="font-semibold text-black">{{ discount ? '(' + Number(discount.value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) + ')' : '' }}</p>
        </div>
      </div>
      <vs-divider />
      <div class="flex items-center justify-between gap-2 mt-3">
        <p class="text-darkgray text-sm font-medium">{{ $t('Subtotal') }}</p>
        <p class="font-semibold text-black">{{ orderData.order_price ? Number(orderData.order_price.subtotal).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(orderData.request_price.subtotal).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
      </div>
      <div class="flex items-center justify-between gap-2 mt-3">
        <p class="text-darkgray text-sm font-medium">{{ $t('VAT (14%)') }}</p>
        <p class="font-semibold text-black">{{ orderData.order_price ? Number(orderData.order_price.vat).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(orderData.request_price.vat).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
      </div>
      <vx-input-group v-if="$route.params.type === 'delivery-orders' && merchantStatus === 'active'" class="mt-4 w-full">
        <vs-input :disabled="!($store.state.AppActiveUser.userRole === 'merchant' && (orderData.status === 'out_for_pickup' || orderData.status === 'pickup_requested' || orderData.status === 'created'))" :placeholder="$t('Apply Promo Code')" @keyup.enter.native="editPromoCode" v-model="orderData.discount_code" />
        <template slot="append">
          <div class="append-text btn-addon">
            <vs-button :disabled="!($store.state.AppActiveUser.userRole === 'merchant' && (orderData.status === 'out_for_pickup' || orderData.status === 'pickup_requested' || orderData.status === 'created'))" @click="editPromoCode" color="#0A3266">{{ $t('Apply') }}</vs-button>
          </div>
        </template>
      </vx-input-group>
      <vs-divider />
      <div class="flex items-center justify-between gap-2">
        <p class="text-black font-semibold text-lg">{{ $t('Total Price') }}</p>
        <p class="text-black font-semibold text-lg">{{ orderData.order_price ? Number(orderData.order_price.total).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(orderData.request_price.total).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
      </div>
    </div>
    <div v-if="(orderData.status === 'delivered' || orderData.status === 'returned') && !orderData.is_exchange" class="mt-5" @click="getInvoice(orderData)">
      <vs-button id="invoice-btn-loading" class="vs-con-loading__container w-full" icon="download">{{$t('Download Invoice')}}</vs-button>
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import i18nData from '../../../i18n/i18nData'
import { sendRequest } from '../../../http/axios/requestHelper'
import common from '../../../assets/utils/common'

export default {
  props: ['orderData', 'priceCardLoading', 'merchantStatus'],
  data () {
    return {
      routerTypesDic: {'delivery-orders': 'returns', 'returns': 'delivery-orders'}
    }
  },
  methods: {
    editPromoCode () {
      this.priceCardLoading = true
      sendRequest(true, false, this, `api/v1/${this.$route.params.type}/${this.$route.params.orderID}/`, 'patch', {discount_code: this.orderData.discount_code}, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Delivery Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.$emit('loadOrder', 'priceCardLoading')
        },
        (error) => {
          common.notifyResponseError(this, error)
          this.$emit('loadOrder', 'priceCardLoading')
        }
      )
    },
    getInvoice (data) {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#invoice-btn-loading',
        scale: 0.45
      })
      sendRequest(true, false, this, `api/v1/billing/merchants/${this.orderData.merchant.id}/invoices/?invoiceorder__order=${data.id}`, 'get', null, true, 
        (response) => {
          if (response.data.results.length > 0) {
            this.downloadInvoice(response.data.results[0].id)
          } else {
            this.$vs.notify({
              color:'warning',
              title:i18nData[this.$i18n.locale]['Warning'],
              text:i18nData[this.$i18n.locale]['Invoice isn\'t ready'],
              position: 'top-center'
            })
            this.$vs.loading.close('#invoice-btn-loading > .con-vs-loading')
          }
        }
      )
    },
    downloadInvoice (invoiceId) {
      sendRequest(true, false, this, `api/v1/billing/invoices/${invoiceId}/pdf/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
          this.$vs.loading.close('#invoice-btn-loading > .con-vs-loading')
        }
      )
    }
  },
  components: {
    ShipbluCard
  }
}
</script>