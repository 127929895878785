<template>
  <shipblu-card :cardLoading="customerInfoCardLoading" class="p-5">
    <div class="flex justify-between items-center">
      <p class="header">{{ $t('Customer Info') }}</p>
      <div v-if="$store.state.AppActiveUser.userRole === 'merchant' && merchantStatus === 'active'">
        <div class="flex" v-if="orderData.status === 'created' || orderData.status === 'return_requested' || orderData.status === 'pickup_requested'">
          <feather-icon icon="EditIcon" svgClasses="h-5 w-5" class="text-primary cursor-pointer" 
            v-if="!editable && !(orderData.status === 'pickup_requested' && $route.params.type === 'returns')" @click="editCustomerInformation()" />
        </div>
      </div>
      <div class="flex" v-else-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'head-of-fleet' || $store.state.AppActiveUser.userRole === 'customer-support'">
        <feather-icon icon="EditIcon" svgClasses="h-5 w-5" class="text-primary cursor-pointer" 
          v-if="!editable && !(orderData.status === 'delivered' || orderData.status === 'returned' || orderData.status === 'returned_to_origin' || orderData.status === 'cancelled')" @click="editCustomerInformation()" />
      </div>
    </div>
    <div class="mt-4 grid sm:grid-cols-2 grid-cols-1 sm:gap-x-6 gap-4 relative">
      <div class="col-span-1">
        <div>
          <div class="flex items-center gap-x-2">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Customer Name') }}</p>
          </div>
          <div class="pl-6 mt-1">
            <p v-show="!editable" class="text-black font-medium">{{ orderData.customer.full_name }}</p>
            <div v-show="editable">
              <vs-input v-validate="'required'" name="full name" :placeholder="$t('Full Name')" v-model="orderData.customer.full_name" class="w-full order-view-input"/>
              <span class="text-danger text-sm" v-show="errors.has('full name')">{{ errors.first('full name') }}</span>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="$store.state.AppActiveUser.userRole !== 'head-of-fleet' && $store.state.AppActiveUser.userRole !== 'manager'">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="PhoneIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Customer Phone') }}</p>
          </div>
          <div class="pl-6 mt-1">
            <div v-if="!editable" class="flex items-center gap-5">
              <p class="text-black font-medium">{{ orderData.customer.phone }}</p>
              <div class="flex items-center">
                <span @click="copied(orderData.customer.phone, 'phoneCopied')" class="material-icons-round text-2xl text-grey hover:text-primary cursor-pointer">copy_all</span>
                <span v-if="phoneCopied" class="absolute ml-6 text-xs font-medium">{{ $t('Copied') }}</span>
              </div>
            </div>
            <div v-show="editable">
              <vs-input v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="phone number" :placeholder="$t('Phone Number')" v-model="orderData.customer.phone" class="w-full order-view-input"/>
              <span class="text-danger text-sm" v-show="errors.has('phone number')">{{ errors.first('phone number') }}</span>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="$store.state.AppActiveUser.userRole !== 'head-of-fleet' && $store.state.AppActiveUser.userRole !== 'manager'">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="PhoneIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Additional Phone Number') }}</p>
          </div>
          <div class="pl-6 mt-1">
            <div v-if="!editable" class="flex items-center gap-5">
              <p class="text-black font-medium">{{ orderData.customer.secondary_phone ? orderData.customer.secondary_phone : '--' }}</p>
              <div class="flex items-center">
                <p v-if="orderData.customer.secondary_phone" @click="copied(orderData.customer.secondary_phone, 'additionalPhoneCopied')" class="material-icons-round text-2xl text-grey hover:text-primary cursor-pointer">copy_all</p>
                <span v-if="additionalPhoneCopied" class="absolute ml-6 text-xs font-medium">{{ $t('Copied') }}</span>
              </div>
            </div>
            <div v-show="editable">
              <vs-input v-validate="{ regex: /(^0[0-9]\d{8,15}$|^\u0660\u0661[\u0660-\u0669][\u0660-\u0669]{8,15}$)/ }" name="secondary phone number" :placeholder="$t('Phone Number')" v-model="orderData.customer.secondary_phone" class="w-full order-view-input"/>
              <span class="text-danger text-sm" v-show="errors.has('secondary phone number')">{{ errors.first('secondary phone number') }}</span>
            </div>
          </div>
        </div>
        <div v-if="$route.params.type !== 'cash-collections'" class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="ClockIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Service Level') }}</p>
          </div>
          <div class="pl-6 mt-1">
            <p v-show="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" class="text-black font-medium">{{ orderData.service_level? orderData.service_level.name : '' }}</p>
            <div v-show="editable && localStorage.getItem('multipleWarehouses') === 'true'">
              <v-select class="select-large w-full" v-model="orderData.service_level" v-validate="'required'"
                :placeholder="$t('Service Level')" name="service level" label="name" :options="serviceLevels"/>
              <span class="text-danger text-sm" v-show="errors.has('service level')">{{ errors.first('service level') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="separator-line sm:block hidden"></div>
      <div class="col-span-1">
        <div>
          <div class="flex items-center gap-x-2">
            <feather-icon icon="MapPinIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Customer Address') }}
              <span class="text-primary text-sm items-center inline-flex mx-1" v-if="orderData.customer.address.auto_zoned"> 
                (<span class="material-icons text-base mr-1">smart_toy</span>{{ $t('autozoned') }})
              </span>
            </p>
          </div>
          <div class="pl-6 mt-1">
            <p v-show="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" class="text-black font-medium">{{ orderData.customer.address.line_1 }}, {{ orderData.customer.address.line_2 }}</p>
            <div v-show="editable && localStorage.getItem('multipleWarehouses') === 'true'">
              <vs-input v-validate="'required'" name="address 1" :placeholder="$t('Address 1')" v-model="orderData.customer.address.line_1" class="w-full order-view-input"/>
              <span class="text-danger text-sm" v-show="errors.has('address 1')">{{ errors.first('address 1') }}</span>
            </div>
          </div>
        </div>
        <div class="mt-4" v-show="editable && localStorage.getItem('multipleWarehouses') === 'true'">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="MapPinIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Customer Address 2') }}</p>
          </div>
          <div class="pl-6 mt-1">
            <vs-input v-validate="'required'" name="address 2" :placeholder="$t('Address 2')" v-model="orderData.customer.address.line_2" class="w-full order-view-input"/>
            <span class="text-danger text-sm" v-show="errors.has('address 2')">{{ errors.first('address 2') }}</span>
          </div>
        </div>
        <div class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="MapPinIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p v-show="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" class="font-medium text-darkgray text-sm leading-tight">{{ $t('Customer Governorate & City') }}</p>
            <p v-show="editable && localStorage.getItem('multipleWarehouses') === 'true'" class="font-medium text-darkgray text-sm leading-tight">{{ $t('Customer Governorate') }}</p>
          </div>
          <div class="pl-6 mt-1">
            <p v-show="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" class="text-black font-medium">{{ $t(orderData.customer.address.zone.city.governorate.name) }} / {{ $t(orderData.customer.address.zone.city.name) }}</p>
            <v-select v-show="editable && localStorage.getItem('multipleWarehouses') === 'true'" autocomplete="nofill" :options="governorates" class="w-full" name="governorate" label="name" item-text="name"
              v-model="governorate" item-value="id" return-object v-on:input="governorate ? (orderData.service_level = '', loadCities(`${governorate.id}`)) : zones = [], zone = '', cities = [], city = ''"
              :placeholder="$t('Governorate')" v-validate="'required'"></v-select>
            <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
          </div>
        </div>
        <div class="mt-4" v-show="editable && localStorage.getItem('multipleWarehouses') === 'true'">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="MapPinIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Customer City') }}</p>
          </div>
          <div class="pl-6 mt-1">
            <v-select autocomplete="nofill" :options="cities" class="w-full" name="city" label="name" item-text="name"
              v-model="city" item-value="id" return-object v-on:input="city ? loadZones(`${city.id}`) : zones = [], zone = ''"
              :placeholder="$t('City')" v-validate="'required'"></v-select>
            <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
          </div>
        </div>
        <div class="mt-4">
          <div class="flex items-center gap-x-2">
            <feather-icon icon="MapPinIcon" svgClasses="w-4 h-4" class="text-grey" />
            <p class="font-medium text-darkgray text-sm leading-tight">{{ $t('Customer Zone') }}</p>
          </div>
          <div class="pl-6 mt-1">
            <p v-show="!editable || localStorage.getItem('multipleWarehouses') !== 'true'" class="text-black font-medium">{{ $t(orderData.customer.address.zone.name) }}</p>
            <v-select v-show="editable && localStorage.getItem('multipleWarehouses') === 'true'" autocomplete="nofill" :options="zones" class="w-full" name="zone" label="name" item-text="name"
              v-model="zone" item-value="id" return-object :placeholder="$t('Zone')" v-validate="'required'"></v-select>
            <span class="text-danger text-sm" v-show="errors.has('zone')">{{ errors.first('zone') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$store.state.AppActiveUser.userRole === 'merchant'">
      <div class="flex gap-4 items-center justify-end" v-if="orderData.status === 'created' || orderData.status === 'return_requested' || orderData.status === 'pickup_requested'">
        <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="cancelEdit()" type="border" color="secondary">{{$t('Cancel')}}</vs-button>
        <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="updateOrder()">{{$t('Save')}}</vs-button>
      </div>
    </div>
    <div class="flex gap-4 items-center justify-end" v-else-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'head-of-fleet' || $store.state.AppActiveUser.userRole === 'customer-support'">
      <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="cancelEdit()" type="border" color="secondary">{{$t('Cancel')}}</vs-button>
      <vs-button class="mt-6 font-semibold text-sm" v-if="editable" @click="updateOrder()">{{$t('Save')}}</vs-button>
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import {sendRequest} from '@/http/axios/requestHelper.js'
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData'
import common from '../../../assets/utils/common'

export default {
  props: ['orderData', 'customerInfoCardLoading', 'merchantStatus'],
  data () {
    return {
      localStorage,
      editable: false,
      pickupPoints: [],
      serviceLevels: [],
      governorates: [],
      cities: [],
      zones: [],
      customer: {
        address: {
          zone: {
            city: {
              governorate: {}
            }
          }
        }
      },
      governorate: {},
      city: {},
      zone: {},
      phoneCopied: false,
      additionalPhoneCopied: false
    }
  },
  watch: {
    'orderData' () {
      this.governorate = {
        id: this.orderData.customer.address.zone.city.governorate.id,
        name: this.orderData.customer.address.zone.city.governorate.name
      }
      this.city = {
        id: this.orderData.customer.address.zone.city.id,
        name: this.orderData.customer.address.zone.city.name
      }
      this.zone = {
        id: this.orderData.customer.address.zone.id,
        name: this.orderData.customer.address.zone.name
      }
    }
  },
  methods: {
    async copied (data, type) {
      try {
        await navigator.clipboard.writeText(data)
        this[type] = true
        setTimeout(() => {
          this[type] = false
        }, 750)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    },
    editCustomerInformation () {
      this.customerInfoCardLoading = true
      this.loadGovernorates()
      this.editable = true
    },
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
        this.loadCities(this.governorate.id)
      })
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
        this.loadServicesAvailability(governorateID)
        if (this.city) {
          this.loadZones(this.city.id)
        }
      })
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data.filter(item => item.id !== 284)
        this.customerInfoCardLoading = false
      })
    },
    loadServicesAvailability (id) {
      sendRequest(true, false, this, `api/v1/services-availability/?governorate_id=${id}&subscription_id=${this.orderData.merchant.subscription.id}`, 'get', null, true, 
        (response) => {
          const key = 'id'
          const packageSizes  = response.data.map(element => element.package_size)
          const serviceLevels = response.data.map(element => element.service_level)
          this.packageSizes = [...new Map(packageSizes.map(item => [item[key], item])).values()]
          this.serviceLevels = [...new Map(serviceLevels.map(item => [item[key], item])).values()]
        }
      )
    },
    cancelEdit () {
      this.editable = !this.editable
      this.$emit('loadOrder', 'customerInfoCardLoading')
    },
    updateOrder () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.customerInfoCardLoading = true
          const shipmentObj = {
            is_pudo: false,
            customer: {
              id: this.orderData.customer.id,
              full_name: this.orderData.customer.full_name,
              email: this.orderData.customer.email,
              phone: this.orderData.customer.phone,
              secondary_phone: this.orderData.customer.secondary_phone ? this.orderData.customer.secondary_phone : null,
              address: {
                id: this.orderData.customer.address.id,
                zone: this.zone.id,
                line_1: this.orderData.customer.address.line_1,
                line_2: this.orderData.customer.address.line_2,
                line_3: this.orderData.customer.address.line_3
              }
            },
            service_level: this.$route.params.type === 'cash-collections' ? null : this.orderData.service_level.id
          }
          sendRequest(true, false, this, `api/v1/${this.$route.params.type}/${this.$route.params.orderID}/`, 'patch', shipmentObj, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Delivery Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.cancelEdit()
            },
            (error) => {
              common.notifyResponseError(this, error)
              this.$emit('loadOrder', 'customerInfoCardLoading')
            }
          )
        }
      })
    }
  },
  components: {
    ShipbluCard,
    vSelect
  }
}
</script>